import { SubscriptionRecurringIntervals } from "@aft/constants/data/subscription";
import { SortOrderTypes } from "@aft/constants/general/sorting";
import { getSortObjectsFunction } from "@aft/utils/getSortObjectsFunction";

/**
 * Format list of price data objects to a format, required on client client.
 *
 * @param prices - List of price data objects.
 */
export const formatPricesList = (prices) => {
  const maximalMonthlyPrice = Math.max(
    ...prices.map(
      ({ interval, intervalCount, amount }) =>
        amount / (intervalCount * (interval === SubscriptionRecurringIntervals.Year ? 12 : 1)),
    ),
  );

  return prices
    .map(({ id, currency, interval, intervalCount, amount }) => {
      const intervalMonthCount =
        intervalCount * (interval === SubscriptionRecurringIntervals.Year ? 12 : 1);

      const totalPrice = amount;
      const monthlyPrice = totalPrice / intervalMonthCount;
      const discount = maximalMonthlyPrice - monthlyPrice;
      const discountRelative = discount / maximalMonthlyPrice;

      return {
        id,
        currency,
        totalPrice,
        monthlyPrice,
        discount,
        discountRelative,
        interval,
        intervalMonth: SubscriptionRecurringIntervals.Month,
        intervalCount,
        intervalMonthCount,
        intervalName: `${intervalCount} ${interval}`,
        intervalMonthName: `${intervalMonthCount} ${SubscriptionRecurringIntervals.Month}`,
      };
    })
    .sort(getSortObjectsFunction("monthlyPrice", SortOrderTypes.Ascending));
};
