import {
  SET_CURRENT_USER_PAYMENT_METHOD,
  RESET_CURRENT_USER_PAYMENT_METHOD,
  GET_CURRENT_USER_PAYMENT_METHOD_SUCCESS,
  UPDATE_CURRENT_USER_PAYMENT_METHOD_SUCCESS,
} from "./actionTypes";

export const paymentMethodReducerInitialState = {
  paymentMethod: undefined,
};

/**
 * Reducer, responsible for handling actions and store state relative to payment method.
 *
 * @param state - Current state of the reducer.
 * @param action - Action to be applied.
 */
export const paymentMethodReducer = (state = paymentMethodReducerInitialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER_PAYMENT_METHOD:
    case GET_CURRENT_USER_PAYMENT_METHOD_SUCCESS:
    case UPDATE_CURRENT_USER_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,

        paymentMethod: action.paymentMethod,
      };
    case RESET_CURRENT_USER_PAYMENT_METHOD:
      return {
        ...state,

        paymentMethod: undefined,
      };
    default:
      return state;
  }
};
