import {
  SET_SELECTED_SUBSCRIPTION_PRICE_ID,
  SET_UPCOMING_PAYMENT,
  GET_SUBSCRIPTION_PRICES_REQUEST,
  GET_SUBSCRIPTION_PRICES_SUCCESS,
  GET_SUBSCRIPTION_PRICES_FAILURE,
  CREATE_CURRENT_USER_SUBSCRIPTION_REQUEST,
  CREATE_CURRENT_USER_SUBSCRIPTION_SUCCESS,
  CREATE_CURRENT_USER_SUBSCRIPTION_FAILURE,
  UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST,
  UPDATE_CURRENT_USER_SUBSCRIPTION_SUCCESS,
  UPDATE_CURRENT_USER_SUBSCRIPTION_FAILURE,
  CANCEL_CURRENT_USER_SUBSCRIPTION_REQUEST,
  CANCEL_CURRENT_USER_SUBSCRIPTION_SUCCESS,
  CANCEL_CURRENT_USER_SUBSCRIPTION_FAILURE,
  GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_REQUEST,
  GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_SUCCESS,
  GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_FAILURE,
} from "./actionTypes";

/**
 * Set selected subscription plan.
 *
 * @param subscriptionPriceId - Subscription price ID.
 */
export const setSelectedSubscriptionPriceId = (subscriptionPriceId) => ({
  type: SET_SELECTED_SUBSCRIPTION_PRICE_ID,
  subscriptionPriceId,
});

/**
 * Set upcoming payment.
 *
 * @param upcomingPayment - Upcoming payment info.
 */
export const setUpcomingPayment = (upcomingPayment) => ({
  type: SET_UPCOMING_PAYMENT,
  upcomingPayment,
});

/**
 * Subscription prices get request action.
 */
export const getSubscriptionPricesRequest = () => ({
  type: GET_SUBSCRIPTION_PRICES_REQUEST,
});

/**
 * Callback action for successful getting subscription prices request attempt.
 *
 * @param subscriptionPrices - List of subscription prices.
 */
export const getSubscriptionPricesSuccess = (subscriptionPrices) => ({
  type: GET_SUBSCRIPTION_PRICES_SUCCESS,
  subscriptionPrices,
});

/**
 * Callback action for failed getting subscription prices request attempt.
 *
 * @param error - Error message.
 */
export const getSubscriptionPricesFailure = (error) => ({
  type: GET_SUBSCRIPTION_PRICES_FAILURE,
  error,
});

/**
 * Current user subscription creation request action.
 *
 * @param subscriptionPriceId - Subscription price ID.
 * @param paymentMethodId - Payment method ID.
 */
export const createCurrentUserSubscriptionRequest = (subscriptionPriceId, paymentMethodId) => ({
  type: CREATE_CURRENT_USER_SUBSCRIPTION_REQUEST,
  subscriptionPriceId,
  paymentMethodId,
});

/**
 * Callback action for successful user subscription creation request attempt.
 *
 * @param upcomingPayment - Upcoming payment info.
 */
export const createCurrentUserSubscriptionSuccess = (upcomingPayment) => ({
  type: CREATE_CURRENT_USER_SUBSCRIPTION_SUCCESS,
  upcomingPayment,
});

/**
 * Callback action for failed user subscription creation request attempt.
 *
 * @param error - Error message.
 */
export const createCurrentUserSubscriptionFailure = (error) => ({
  type: CREATE_CURRENT_USER_SUBSCRIPTION_FAILURE,
  error,
});

/**
 * Current user subscription update request action.
 *
 * @param subscriptionPriceId - Subscription price ID.
 */
export const updateCurrentUserSubscriptionRequest = (subscriptionPriceId) => ({
  type: UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST,
  subscriptionPriceId,
});

/**
 * Callback action for successful user subscription update request attempt.
 *
 * @param upcomingPayment - Upcoming payment info.
 */
export const updateCurrentUserSubscriptionSuccess = (upcomingPayment) => ({
  type: UPDATE_CURRENT_USER_SUBSCRIPTION_SUCCESS,
  upcomingPayment,
});

/**
 * Callback action for failed user subscription update request attempt.
 *
 * @param error - Error message.
 */
export const updateCurrentUserSubscriptionFailure = (error) => ({
  type: UPDATE_CURRENT_USER_SUBSCRIPTION_FAILURE,
  error,
});

/**
 * Current user subscription cancel request action.
 *
 * @param surveyData - Survey data.
 */
export const cancelCurrentUserSubscriptionRequest = (surveyData) => ({
  type: CANCEL_CURRENT_USER_SUBSCRIPTION_REQUEST,
  surveyData,
});

/**
 * Callback action for successful user subscription cancel request attempt.
 */
export const cancelCurrentUserSubscriptionSuccess = () => ({
  type: CANCEL_CURRENT_USER_SUBSCRIPTION_SUCCESS,
});

/**
 * Callback action for failed user subscription cancel request attempt.
 *
 * @param error - Error message.
 */
export const cancelCurrentUserSubscriptionFailure = (error) => ({
  type: CANCEL_CURRENT_USER_SUBSCRIPTION_FAILURE,
  error,
});

/**
 * Current user subscription upcoming payment request action.
 */
export const getCurrentUserSubscriptionPaymentUpcomingRequest = () => ({
  type: GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_REQUEST,
});

/**
 * Callback action for successful user subscription upcoming payment request attempt.
 *
 * @param upcomingPayment - Upcoming payment info.
 */
export const getCurrentUserSubscriptionPaymentUpcomingSuccess = (upcomingPayment) => ({
  type: GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_SUCCESS,
  upcomingPayment,
});

/**
 * Callback action for failed user subscription upcoming payment request attempt.
 *
 * @param error - Error message.
 */
export const getCurrentUserSubscriptionPaymentUpcomingFailure = (error) => ({
  type: GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_FAILURE,
  error,
});
