const PREFIX = `SUBSCRIPTION/`;

export const SET_SELECTED_SUBSCRIPTION_PRICE_ID = `${PREFIX}SET_SELECTED_SUBSCRIPTION_PRICE_ID`;
export const SET_UPCOMING_PAYMENT = `${PREFIX}SET_UPCOMING_PAYMENT`;

export const GET_SUBSCRIPTION_PRICES_REQUEST = `${PREFIX}GET_SUBSCRIPTION_PRICES_REQUEST`;
export const GET_SUBSCRIPTION_PRICES_SUCCESS = `${PREFIX}GET_SUBSCRIPTION_PRICES_SUCCESS`;
export const GET_SUBSCRIPTION_PRICES_FAILURE = `${PREFIX}GET_SUBSCRIPTION_PRICES_FAILURE`;

export const CREATE_CURRENT_USER_SUBSCRIPTION_REQUEST = `${PREFIX}CREATE_CURRENT_USER_SUBSCRIPTION_REQUEST`;
export const CREATE_CURRENT_USER_SUBSCRIPTION_SUCCESS = `${PREFIX}CREATE_CURRENT_USER_SUBSCRIPTION_SUCCESS`;
export const CREATE_CURRENT_USER_SUBSCRIPTION_FAILURE = `${PREFIX}CREATE_CURRENT_USER_SUBSCRIPTION_FAILURE`;

export const UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST = `${PREFIX}UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST`;
export const UPDATE_CURRENT_USER_SUBSCRIPTION_SUCCESS = `${PREFIX}UPDATE_CURRENT_USER_SUBSCRIPTION_SUCCESS`;
export const UPDATE_CURRENT_USER_SUBSCRIPTION_FAILURE = `${PREFIX}UPDATE_CURRENT_USER_SUBSCRIPTION_FAILURE`;

export const CANCEL_CURRENT_USER_SUBSCRIPTION_REQUEST = `${PREFIX}CANCEL_CURRENT_USER_SUBSCRIPTION_REQUEST`;
export const CANCEL_CURRENT_USER_SUBSCRIPTION_SUCCESS = `${PREFIX}CANCEL_CURRENT_USER_SUBSCRIPTION_SUCCESS`;
export const CANCEL_CURRENT_USER_SUBSCRIPTION_FAILURE = `${PREFIX}CANCEL_CURRENT_USER_SUBSCRIPTION_FAILURE`;

export const GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_REQUEST = `${PREFIX}GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_REQUEST`;
export const GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_SUCCESS = `${PREFIX}GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_SUCCESS`;
export const GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_FAILURE = `${PREFIX}GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_FAILURE`;
