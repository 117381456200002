import {
  SET_CURRENT_USER_PAYMENT_METHOD,
  RESET_CURRENT_USER_PAYMENT_METHOD,
  GET_CURRENT_USER_PAYMENT_METHOD_REQUEST,
  GET_CURRENT_USER_PAYMENT_METHOD_SUCCESS,
  GET_CURRENT_USER_PAYMENT_METHOD_FAILURE,
  UPDATE_CURRENT_USER_PAYMENT_METHOD_REQUEST,
  UPDATE_CURRENT_USER_PAYMENT_METHOD_SUCCESS,
  UPDATE_CURRENT_USER_PAYMENT_METHOD_FAILURE,
} from "./actionTypes";

/**
 * Set current user payment method to a store.
 *
 * @param paymentMethod - Payment method.
 */
export const setCurrentUserPaymentMethod = (paymentMethod) => ({
  type: SET_CURRENT_USER_PAYMENT_METHOD,
  paymentMethod,
});

/**
 * Reset current user payment method in a store.
 */
export const resetCurrentUserPaymentMethod = () => ({
  type: RESET_CURRENT_USER_PAYMENT_METHOD,
});

/**
 * Current user payment method get request action.
 */
export const getCurrentUserPaymentMethodRequest = () => ({
  type: GET_CURRENT_USER_PAYMENT_METHOD_REQUEST,
});

/**
 * Callback action for successful user payment method getting request attempt.
 *
 * @param paymentMethod - Payment method.
 */
export const getCurrentUserPaymentMethodSuccess = (paymentMethod) => ({
  type: GET_CURRENT_USER_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
});

/**
 * Callback action for failed user payment method getting request attempt.
 *
 * @param error - Error message.
 */
export const getCurrentUserPaymentMethodFailure = (error) => ({
  type: GET_CURRENT_USER_PAYMENT_METHOD_FAILURE,
  error,
});

/**
 * Current user payment method update request action.
 *
 * @param stripePaymentMethodId - Stripe payment method ID.
 */
export const updateCurrentUserPaymentMethodRequest = (stripePaymentMethodId) => ({
  type: UPDATE_CURRENT_USER_PAYMENT_METHOD_REQUEST,
  stripePaymentMethodId,
});

/**
 * Callback action for successful user payment method update request attempt.
 *
 * @param paymentMethod - Payment method.
 */
export const updateCurrentUserPaymentMethodSuccess = (paymentMethod) => ({
  type: UPDATE_CURRENT_USER_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
});

/**
 * Callback action for failed user payment method update request attempt.
 *
 * @param error - Error message.
 */
export const updateCurrentUserPaymentMethodFailure = (error) => ({
  type: UPDATE_CURRENT_USER_PAYMENT_METHOD_FAILURE,
  error,
});
