import { SortOrderTypes } from "@aft/constants/general/sorting";

/**
 * Returns a function allow sort array of object by specified field.
 *
 * @param sortField - Name of the field to sort data by.
 * @param sortOrder - Sort order.
 */
export const getSortObjectsFunction = (sortField, sortOrder = SortOrderTypes.Descending) => (
  currentRecord,
  nextRecord,
) => {
  const currentItem =
    typeof currentRecord[sortField] === "string"
      ? currentRecord[sortField].toLowerCase()
      : currentRecord[sortField];
  const nextItem =
    typeof nextRecord[sortField] === "string"
      ? nextRecord[sortField].toLowerCase()
      : nextRecord[sortField];

  if (currentItem === undefined || currentItem === null) {
    return 1;
  }

  if (nextItem === undefined || nextItem === null) {
    return -1;
  }

  if (currentItem === nextItem) {
    return 0;
  }

  if (currentItem > nextItem) {
    return sortOrder === SortOrderTypes.Descending ? -1 : 1;
  }

  return sortOrder === SortOrderTypes.Descending ? 1 : -1;
};
