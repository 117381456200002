/**
 * Set of the possible sort order types.
 */
const SortOrderTypes = {
  /**
   * Descending.
   */
  Descending: "desc",

  /**
   * Ascending.
   */
  Ascending: "asc",
};

module.exports = {
  SortOrderTypes,
};
