import { call, put, takeLatest } from "redux-saga/effects";

import { accountActions } from "@aft/client-modules/account";
import { applicationSagas } from "@aft/client-modules/application";
import { paymentMethodActions } from "@aft/client-modules/paymentMethod";
import { serverApiClient } from "@aft/server/apiClient";

import {
  GET_SUBSCRIPTION_PRICES_REQUEST,
  CREATE_CURRENT_USER_SUBSCRIPTION_REQUEST,
  UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST,
  CANCEL_CURRENT_USER_SUBSCRIPTION_REQUEST,
  GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_REQUEST,
} from "./actionTypes";
import {
  getSubscriptionPricesSuccess,
  getSubscriptionPricesFailure,
  createCurrentUserSubscriptionSuccess,
  createCurrentUserSubscriptionFailure,
  updateCurrentUserSubscriptionSuccess,
  updateCurrentUserSubscriptionFailure,
  cancelCurrentUserSubscriptionSuccess,
  cancelCurrentUserSubscriptionFailure,
  getCurrentUserSubscriptionPaymentUpcomingSuccess,
  getCurrentUserSubscriptionPaymentUpcomingFailure,
} from "./actions";
import { formatPricesList } from "./formatters";

function* getSubscriptionPrices() {
  try {
    const { docs } = yield call(serverApiClient.subscription.getPrices);

    yield put(getSubscriptionPricesSuccess(formatPricesList(docs)));
  } catch (err) {
    yield put(getSubscriptionPricesFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* createCurrentUserSubscription({ subscriptionPriceId, paymentMethodId }) {
  try {
    const { user, upcomingPayment, paymentMethod } = yield call(
      serverApiClient.users.createCurrentUserSubscription,
      subscriptionPriceId,
      paymentMethodId,
    );

    yield put(accountActions.setCurrentUserData(user));
    yield put(paymentMethodActions.setCurrentUserPaymentMethod(paymentMethod));
    yield put(createCurrentUserSubscriptionSuccess(upcomingPayment));
  } catch (err) {
    yield put(createCurrentUserSubscriptionFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* updateCurrentUserSubscription({ subscriptionPriceId }) {
  try {
    const { user, upcomingPayment } = yield call(
      serverApiClient.users.updateCurrentUserSubscription,
      subscriptionPriceId,
    );

    yield put(accountActions.setCurrentUserData(user));
    yield put(updateCurrentUserSubscriptionSuccess(upcomingPayment));
  } catch (err) {
    yield put(updateCurrentUserSubscriptionFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* cancelCurrentUserSubscription({ surveyData }) {
  try {
    const user = yield call(serverApiClient.users.cancelCurrentUserSubscription, surveyData);

    yield put(accountActions.setCurrentUserData(user));
    yield put(paymentMethodActions.resetCurrentUserPaymentMethod());
    yield put(cancelCurrentUserSubscriptionSuccess());
  } catch (err) {
    yield put(cancelCurrentUserSubscriptionFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* getCurrentUserSubscriptionPaymentUpcoming() {
  try {
    const upcomingPayment = yield call(
      serverApiClient.users.getCurrentUserSubscriptionPaymentUpcoming,
    );

    yield put(getCurrentUserSubscriptionPaymentUpcomingSuccess(upcomingPayment));
  } catch (err) {
    yield put(getCurrentUserSubscriptionPaymentUpcomingFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

/**
 * Root saga of the subscription module.
 */
export function* subscriptionSaga() {
  yield takeLatest(GET_SUBSCRIPTION_PRICES_REQUEST, getSubscriptionPrices);
  yield takeLatest(CREATE_CURRENT_USER_SUBSCRIPTION_REQUEST, createCurrentUserSubscription);
  yield takeLatest(UPDATE_CURRENT_USER_SUBSCRIPTION_REQUEST, updateCurrentUserSubscription);
  yield takeLatest(CANCEL_CURRENT_USER_SUBSCRIPTION_REQUEST, cancelCurrentUserSubscription);
  yield takeLatest(
    GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_REQUEST,
    getCurrentUserSubscriptionPaymentUpcoming,
  );
}
