import {
  SET_SELECTED_SUBSCRIPTION_PRICE_ID,
  SET_UPCOMING_PAYMENT,
  GET_SUBSCRIPTION_PRICES_SUCCESS,
  CREATE_CURRENT_USER_SUBSCRIPTION_SUCCESS,
  UPDATE_CURRENT_USER_SUBSCRIPTION_SUCCESS,
  CANCEL_CURRENT_USER_SUBSCRIPTION_SUCCESS,
  GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_SUCCESS,
} from "./actionTypes";

export const subscriptionReducerInitialState = {
  subscriptionPrices: undefined,

  selectedSubscriptionPriceId: undefined,

  upcomingPayment: undefined,
};

/**
 * Reducer, responsible for handling actions and store state relative to subscription.
 *
 * @param state - Current state of the reducer.
 * @param action - Action to be applied.
 */
export const subscriptionReducer = (state = subscriptionReducerInitialState, action) => {
  switch (action.type) {
    case SET_SELECTED_SUBSCRIPTION_PRICE_ID:
      return {
        ...state,

        selectedSubscriptionPriceId: action.subscriptionPriceId,
      };
    case SET_UPCOMING_PAYMENT:
      return {
        ...state,

        upcomingPayment: action.upcomingPayment,
      };
    case GET_SUBSCRIPTION_PRICES_SUCCESS:
      return {
        ...state,

        subscriptionPrices: action.subscriptionPrices,
      };
    case CREATE_CURRENT_USER_SUBSCRIPTION_SUCCESS:
    case UPDATE_CURRENT_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,

        selectedSubscriptionPriceId: undefined,
        upcomingPayment: action.upcomingPayment,
      };
    case CANCEL_CURRENT_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,

        upcomingPayment: undefined,
      };
    case GET_CURRENT_USER_SUBSCRIPTION_PAYMENT_UPCOMING_SUCCESS:
      return {
        ...state,

        upcomingPayment: action.upcomingPayment,
      };
    default:
      return state;
  }
};
