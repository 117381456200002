const PREFIX = `PAYMENT_METHOD/`;

export const SET_CURRENT_USER_PAYMENT_METHOD = `${PREFIX}SET_CURRENT_USER_PAYMENT_METHOD`;
export const RESET_CURRENT_USER_PAYMENT_METHOD = `${PREFIX}RESET_CURRENT_USER_PAYMENT_METHOD`;

export const GET_CURRENT_USER_PAYMENT_METHOD_REQUEST = `${PREFIX}GET_CURRENT_USER_PAYMENT_METHOD_REQUEST`;
export const GET_CURRENT_USER_PAYMENT_METHOD_SUCCESS = `${PREFIX}GET_CURRENT_USER_PAYMENT_METHOD_SUCCESS`;
export const GET_CURRENT_USER_PAYMENT_METHOD_FAILURE = `${PREFIX}GET_CURRENT_USER_PAYMENT_METHOD_FAILURE`;

export const UPDATE_CURRENT_USER_PAYMENT_METHOD_REQUEST = `${PREFIX}UPDATE_CURRENT_USER_PAYMENT_METHOD_REQUEST`;
export const UPDATE_CURRENT_USER_PAYMENT_METHOD_SUCCESS = `${PREFIX}UPDATE_CURRENT_USER_PAYMENT_METHOD_SUCCESS`;
export const UPDATE_CURRENT_USER_PAYMENT_METHOD_FAILURE = `${PREFIX}UPDATE_CURRENT_USER_PAYMENT_METHOD_FAILURE`;
