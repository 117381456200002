import { call, put, takeLatest } from "redux-saga/effects";

import { accountActions } from "@aft/client-modules/account";
import { applicationSagas } from "@aft/client-modules/application";
import { serverApiClient } from "@aft/server/apiClient";

import {
  GET_CURRENT_USER_PAYMENT_METHOD_REQUEST,
  UPDATE_CURRENT_USER_PAYMENT_METHOD_REQUEST,
} from "./actionTypes";
import {
  getCurrentUserPaymentMethodSuccess,
  getCurrentUserPaymentMethodFailure,
  updateCurrentUserPaymentMethodSuccess,
  updateCurrentUserPaymentMethodFailure,
} from "./actions";

function* getCurrentUserPaymentMethod() {
  try {
    const paymentMethod = yield call(serverApiClient.users.getCurrentUserPaymentMethod);

    yield put(getCurrentUserPaymentMethodSuccess(paymentMethod));
  } catch (err) {
    yield put(getCurrentUserPaymentMethodFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* updateCurrentUserPaymentMethod({ stripePaymentMethodId }) {
  try {
    const { paymentMethod, user } = yield call(
      serverApiClient.users.updateCurrentUserPaymentMethod,
      stripePaymentMethodId,
    );

    yield put(accountActions.setCurrentUserData(user));
    yield put(updateCurrentUserPaymentMethodSuccess(paymentMethod));
  } catch (err) {
    yield put(updateCurrentUserPaymentMethodFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

/**
 * Root saga of the payment method module.
 */
export function* paymentMethodSaga() {
  yield takeLatest(GET_CURRENT_USER_PAYMENT_METHOD_REQUEST, getCurrentUserPaymentMethod);
  yield takeLatest(UPDATE_CURRENT_USER_PAYMENT_METHOD_REQUEST, updateCurrentUserPaymentMethod);
}
